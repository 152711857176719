import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";

import dayUse from './images/applications/dayuse.jpg';
import rental from './images/applications/rentals.jpg';
import juice from './images/applications/juice.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-solution.jpg";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";
import GatsbySlideshow from "../../../components/carousel/gatsby-slideshow/GatsbySlideshow";


const IndexPage = (data) => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const d = data.data.Slides.edges;
  const images = [];
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const projectAsides = [
    '<span>france</span><br>' +
    '<h2>Let\'s Ride</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Soul Cycle</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>Croatia</span><br>' +
    '<h2>Croatia Fitness</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>',

    '<span>Russia</span><br>' +
    '<h2>Athlete Factory</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    // '<span>THE LOCATION</span><br>' +
    // '<h2>Trango Towers</h2>' +
    // '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Dial Combo</a></p>',

    '<span>united states</span><br>' +
    '<h2>The Ride House</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>United Kingdom</span><br>' +
    '<h2>UFC</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Earth Treks Climbing Gym</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Roam Fitness</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>'
  ]
  const solutions = [
    {
      img: dayUse,
      label: 'day_use_lockers_gym',
      alt: 'day_use_lockers_gym',
      bg: 'light'
    },
    {
      img: rental,
      label: 'rental_lockers_gym',
      alt: 'rental_lockers_gym',
      bg: 'light'
    },
    {
      img: juice,
      label: 'juicebar_charging_lockers',
      alt: 'JuiceBar: Charging Gym Lockers',
      bg: 'light'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_digital_locker_locks_gyms')}
          description={t('des_solutions_digital_locker_locks_gyms')}
        />
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-health-fitness.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Athlete Factory locker locks"
            />
            <h1>
              <Trans>
                Locker Locks
              </Trans>
              <span>
                <Trans>health_fitness_hero_subhead</Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>health_fitness_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>health_fitness_subhero_list_l1</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l2</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l3</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l4</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l5</Trans></li>
                  <li><Trans>health_fitness_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/healthandfitness.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="fitness"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup
              label={'health_fitness_lineup_label'}
              showMech={true}
              showCurve={true}
              showAspire={true}
              showVersa={true}
              showCleo={true}
              showCTA={true}/>
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>health_fitness_carousel</Trans></h2>
              <GatsbySlideshow images={images} overlays={projectAsides} />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'health_fitness_customer_video_title'}
                copy={'health_fitness_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/wPKl-bZodESn8pgc4NG-1A/1WG3QYG6VUKQ-WgJHTv0ig/Widescreen720p/health-fitness.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless_gym</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(
    filter: {relativeDirectory: {eq: "solutions/digital-locker-locks-gyms"}}
    sort: {fields: [name], order: ASC}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
